<template>
    <div>
        <el-row>
            <el-col :span="2" class="f_w tit">全文检索</el-col>
            <el-col :span="6" class="m_r1">
                <el-input v-model="srhStr" size="small" placeholder="请输入搜索关键词" maxlength="66" clearable></el-input>
            </el-col>
            <el-col :span="4">
                <el-button size="small" type="primary" @click="search2">检索</el-button>
                <el-button size="small" type="text" @click="labelIndexQueryAll2">更多选项</el-button>
            </el-col>
            <el-col :span="2" style="float: right">
                <el-button size="small" type="primary" @click="back">返回</el-button>
            </el-col>
            <div class="more0ptions" v-if="more0ptions">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
                </el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="city in cityOptions" :label="city.id" :key="city.id">{{city.name}}</el-checkbox>
                </el-checkbox-group>
            </div>
        </el-row>
        <div class="m_t1" v-loading="loading">
<!--            <el-button size="small" type="primary" @click="applyBorrow">申请借阅</el-button>-->
            <div class="itemBox" v-if="list.length>0">
                <div v-for="(item, index) in list" :key="index" class="item" >
                    <div class="flex_l_c">
                        <img src="../../../../assets/img/word.png" class="wordIcon" v-if="item.docType == 'doc'">
                        <img src="../../../../assets/img/pdf.png" class="wordIcon" v-if="item.docType == 'pdf'">
                        <div class="omit flex1" v-html="item.docTitle"></div>
                    </div>
                    <div class="content" v-html="item.docContent"></div>
                    <el-row :gutter="20">
                        <el-col :span="22">
                            <div class="m_t1">
                                <el-tag size="mini" v-for="(itemC, indexC) in item.docTitleHighLightList" :key="indexC">{{itemC}}</el-tag>
                            </div>
                        </el-col>
                        <el-col :span="2">
                            <el-button  type="text" @click="view(item.docFileId)">详情</el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <el-empty description="暂无数据" v-else></el-empty>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <BorrowForm ref="borrowForm"></BorrowForm>
        <!--查看-->
        <DetailPage ref="detailPage" @refreshDataList="search"></DetailPage>
    </div>
</template>

<script>
    import BorrowForm from './BorrowForm'
    import DetailPage from './detailPage' // 查看弹窗
    export default {
        components: {BorrowForm, DetailPage},
        data() {
            return {
                srhStr: '',
                more0ptions: false,
                checkAll: false,
                checkedCities: [],
                cityOptions: [],
                isIndeterminate: false,
                list: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                loading:false
            }
        },
        methods: {
            init(srhStr) {
                this.srhStr = srhStr;
                this.search();
            },
            view(row){
                this.$refs.detailPage.init(row, 'itemRetrieval')
            },
            labelIndexQueryAll2(){
                this.more0ptions = !this.more0ptions
                if(this.more0ptions){
                    this.$axios(this.api.common.labelIndexQueryAll2, {}, 'get').then(data => {
                        if (data && data.status) {
                            this.cityOptions = data.data
                        }
                    })
                }
            },
            search2(){
                this.pageNo = 1
                this.pageSize = 10
                this.search();
            },
            // 检索
            search() {
                let params={};
                let paramsJson = {};
                // console.log(this.checkedCities);
                // this.srhStr.replace(/\s/g,"")
                if(this.srhStr.replace(/\s/g,"") === ''){// && this.checkedCities.length==0
                    return;
                }
                this.loading = true
                paramsJson.words = this.srhStr;//查询字符串
                paramsJson.wordsLocation = 0;//文件位置
                paramsJson.docSizeLevel = 0;//文件大小
                paramsJson.docType = "all";//文件类型
                paramsJson.labelId = this.checkedCities.map(item => {
                    return item
                }).join(',');//文件类型
                params.paramsJson = JSON.stringify(paramsJson);
                params.page = this.pageNo;
                params.size = this.pageSize;
                console.log(params);
                this.$axios(this.api.docsearch.searchDoc,params, 'get').then(data => {
                    if (data && data.status) {
                        this.list = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // // 申请借阅
            // applyBorrow() {
            //     this.$refs.borrowForm.init(JSON.parse(JSON.stringify(this.list)))
            // },
            // 全选
            handleCheckAllChange(val) {
                this.checkedCities = val ? this.cityOptions.map(item => {
                    return item.id
                }): [];
                this.isIndeterminate = false;
            },
            // 选择一个
            handleCheckedCitiesChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.cityOptions.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.cityOptions.length;
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.search()
            },
            // 返回
            back() {
                this.$emit('getData')
            },
        }
    }
</script>

<style scoped>
    .tit {
        padding-top: 5px;
    }

    .more0ptions {
        position: absolute;
        top: 36px;
        left: 0;
        z-index: 1;
        background: #ffffff;
        margin-top: 10px;
        padding: 10px 20px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    }
    .itemBox {
        height: calc(100vh - 240px);
        overflow-y: auto;
        margin: 15px 0 0;
        padding: 1px;
    }
    .item {
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
        margin-bottom: 15px;
        padding: 15px;
        color: #333333;
    }
    .wordIcon {
        width: 24px;
        margin-right: 10px;
    }
    .content {
        font-size: 13px;
        margin-top: 10px;
    }
</style>
